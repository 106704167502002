@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700&family=Nunito:wght@400;700&display=swap");


.f-nav ul, .list, .custom-list, .timeline, .clients {
  margin: 0;
  padding: 0;
  list-style: none;
}

.f-nav ul:after, .faq-holder:after, .list:after, .custom-list:after, .timeline:after, .clients:after, .clearfix:after {
  content: "";
  display: block;
  clear: both;
}

a:before, a:after,
.btn:before,
.btn:after,
button:before,
button:after, a,
.btn,
button {
  transition: all 0.3s ease-in-out;
}

.ellipsis {
  white-space: nowrap;
  /* 1 */
  text-overflow: ellipsis;
  /* 2 */
  overflow: hidden;
}

/*  ---------------------------------------------------------
	3. General reset
	---------------------------------------------------------*/
body {
  min-width: 375px;
  -ms-overflow-style: scrollbar;
  background-color: #f9f9f9 !important;
}
body.nav-active {
  overflow: hidden;
}

a,
.btn,
button {
  text-decoration: none;
}
a:focus, a:active,
.btn:focus,
.btn:active,
button:focus,
button:active {
  outline: none;
  box-shadow: none !important;
}
/* h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  letter-spacing: 0.06em;
} */
h1:last-child, h2:last-child, h3:last-child, h4:last-child, h5:last-child, h6:last-child, .h1:last-child, .h2:last-child, .h3:last-child, .h4:last-child, .h5:last-child, .h6:last-child {
  margin-bottom: 0 !important;
}
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a, .h1 a, .h2 a, .h3 a, .h4 a, .h5 a, .h6 a {
  color: inherit;
}
h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover, .h1 a:hover, .h2 a:hover, .h3 a:hover, .h4 a:hover, .h5 a:hover, .h6 a:hover {
  color: #255B80;
}

h1, .h1 {
  margin: 0 0 29px;
}

h2, .h2 {
  line-height: 1.2727;
  margin: 0 0 12px;
}

h4, .h4,
h5, .h5,
h5, .h6 {
  line-height: 1.2857;
  margin: 0 0 14px;
}

p {
  color: #2B2B2B;
  letter-spacing: 0.01em;
}
@media (max-width: 767.98px) {
  p {
    font-size: 1rem !important;
  }
}
p:last-child {
  margin-bottom: 0 !important;
}
p strong {
  font-weight: 600;
}
p a {
  font-weight: 600;
}

.btn {
  white-space: nowrap;
  min-width: 140px;
  border-radius: 3px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.2);
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.btn img {
  margin-left: 18px;
}
.btn.full {
  width: 100%;
}

.btn-secondary {
  color: #fff;
}
.btn-secondary:hover {
  color: #fff;
}

/*  ---------------------------------------------------------
	4. Сommon site styles
	---------------------------------------------------------*/
/* main container styles */
#wrapper {
  position: relative;
  overflow: hidden;
  letter-spacing: 0.01em;
}

/* img {
  display: block;
} */

.navbar-brand {
  width: 176px;
  height: auto;
}
.navbar-brand a {
  display: block;
}
.navbar-brand img {
  width: 100%;
  height: auto;
}

.btn-holder {
  margin: 20px 0;
}
@media (min-width: 992px) {
  .btn-holder {
    margin: 0 0 0 52px;
  }
}

.navbar-toggler {
  margin-left: auto;
}

@media (max-width: 991.98px) {
  .nav-holder {
    width: 100%;
    text-align: center;
  }
}

.navbar-light {
  padding-top: 26px;
  padding-bottom: 26px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
.navbar-light .navbar-nav .nav-item {
  margin: 9px 0;
}
@media (min-width: 768px) {
  .navbar-light .navbar-nav .nav-item {
    margin-left: 30px;
  }
}
@media (min-width: 992px) {
  .navbar-light .navbar-nav .nav-item {
    margin-left: 40px;
  }
}
.navbar-light .navbar-nav .nav-link {
  padding: 0;
  font-size: 1.25rem;
  font-weight: 600;
  color: #183B59;
  display: inline-block;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #A8946B;
}
.navbar-light .navbar-nav .nav-link.active {
  color: #A8946B;
  position: relative;
  font-weight: 600;
}
.navbar-light .navbar-nav .nav-link.active:after {
  content: "";
  background: #A8946B;
  width: auto;
  height: 1px;
  position: absolute;
  top: auto;
  right: 0;
  bottom: -2px;
  left: 0;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

p {
  font-size: 1.25rem;
  line-height: 1.5;
}

.banner {
  overflow: hidden;
  position: relative;
  height: 355px;
  text-align: center;
}
@media (min-width: 576px) {
  .banner {
    height: 555px;
  }
}
@media (min-width: 768px) {
  .banner {
    text-align: left;
  }
}
@media (min-width: 992px) {
  .banner {
    height: 755px;
  }
}
.banner:after {
  content: "";
  width: 119px;
  height: 161px;
  position: absolute;
  top: 50%;
  right: auto;
  bottom: 33px;
  left: 0;
  transform: translateY(-50%);
  background-image: url(../images/shape1.png);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}
@media (max-width: 991.98px) {
  .banner:after {
    margin-left: -140px;
  }
}
@media (max-width: 575.98px) {
  .banner:after {
    margin-left: -80px;
  }
}
@media (min-width: 576px) {
  .banner:after {
    width: 210px;
    height: 287px;
  }
}
@media (min-width: 992px) {
  .banner:after {
    width: 220px;
    height: 304px;
  }
}
@media (min-width: 1400px) {
  .banner:after {
    width: 504px;
    height: 670px;
  }
}
.banner:before {
  content: "";
  width: 85px;
  height: 227px;
  position: absolute;
  top: auto;
  right: 0;
  bottom: 13px;
  left: auto;
  background-image: url(../images/shape-2.svg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}
@media (max-width: 991.98px) {
  .banner:before {
    display: none;
  }
}
@media (min-width: 576px) {
  .banner:before {
    width: 85px;
    height: 227px;
  }
}
@media (min-width: 1240px) {
  .banner:before {
    width: 139px;
    height: 370px;
  }
}
.banner .container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.banner .text-holder {
  max-width: 570px;
  margin-bottom: 30px;
  padding: 0 30px;
}
@media (min-width: 768px) {
  .banner .text-holder {
    margin-left: 15%;
    margin-bottom: 30px;
    padding: 0;
  }
}
@media (max-width: 767.98px) {
  .banner .cta-holder {
    text-align: center;
  }
}
.banner .text {
  font-size: 1.875rem;
  line-height: 1.4;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  width: 750px;
  margin: 0 auto;
}

.cols-five {
  display: flex;
  align-items: flex-end;
  flex-flow: row wrap;
  margin: 0 -13px;
}
.cols-five .col {
  width: 100%;
  padding: 0 13px;
}
@media (min-width: 576px) {
  .cols-five .col {
    width: 50%;
  }
}
@media (min-width: 992px) {
  .cols-five .col {
    width: 20%;
  }
}

.appointment-holder {
  background: rgba(223, 226, 221, 0.4);
  padding-top: 40px;
  padding-bottom: 17px;
}
@media (min-width: 768px) {
  .appointment-holder {
    padding-top: 70px;
  }
}
.appointment-holder .btn {
  width: 100%;
}
.appointment-holder.price {
  padding-top: 60px;
  padding-bottom: 30px;
}
.appointment-holder.price [class^=col-],
.appointment-holder.price .btn {
  margin-bottom: 10px;
}
@media (min-width: 992px) {
  .appointment-holder.price [class^=col-],
.appointment-holder.price .btn {
    margin-bottom: 35px;
  }
}
.appointment-holder [class^=col-] {
  margin-bottom: 5px;
}
@media (min-width: 992px) {
  .appointment-holder [class^=col-] {
    margin-bottom: 0;
  }
}
@media (max-width: 991.98px) {
  .appointment-holder [class^=col-] .btn {
    margin-top: 20px;
  }
}
.appointment-holder .head {
  margin: 0 0 30px;
}
@media (min-width: 768px) {
  .appointment-holder .head {
    margin: 0 0 52px;
  }
}
.appointment-holder label {
  display: block;
  margin-bottom: 10px;
}
@media (min-width: 768px) {
  .appointment-holder label {
    font-size: 1.25rem;
  }
}
.appointment-holder label.small {
  font-size: 0.75rem;
  font-weight: normal;
  font-family: "Nunito", "Montserrat", sans-serif;
}

.shape-holder {
  text-align: center;
  padding: 80px 0;
}
.shape-holder img {
  max-width: 202px;
  margin: 0 auto;
}

.brand {
  max-width: 224px;
  margin: 0 auto 30px;
}
@media (min-width: 576px) {
  .brand {
    max-width: 424px;
  }
}
@media (min-width: 768px) {
  .brand {
    margin: 0 0 31px;
  }
}
.brand img {
  width: 100%;
  height: auto;
}

.clients {
  display: flex;
  flex-flow: wrap row;
  justify-content: center;
  align-items: center;
  max-width: 1097px;
  margin: 0 auto 40px;
}
@media (min-width: 768px) {
  .clients {
    margin: 0 auto 76px;
  }
}
.clients li {
  padding: 0 20px;
  text-align: center;
  width: 50%;
  margin: 10px 0;
}
@media (min-width: 576px) {
  .clients li {
    width: 33.3%;
  }
}
@media (min-width: 768px) {
  .clients li {
    width: 20%;
    justify-content: space-between;
    margin: 0;
  }
}
.clients li img {
  display: inline-block;
  max-width: 100px;
  margin: 0 auto;
}

.img-holder {
  overflow: hidden;
}
.img-holder img {
  width: 100%;
  height: auto;
}

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.price-holder {
  overflow: hidden;
  background: #397087;
  padding-top: 34px;
  color: #fff;
  text-align: center;
}
.price-holder .text {
  max-width: 767px;
  margin: 0 auto;
}
.price-holder p {
  color: #fff;
}
.price-holder h1 {
  color: #D1D5CE;
}
.price-holder .head {
  margin-bottom: 50px;
}

.timeline {
  margin: 60px 0 60px;
}
.timeline li {
  width: 25%;
  text-align: center;
  padding: 0 5px;
  position: relative;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
  margin-left: -4px;
}
@media (min-width: 992px) {
  .timeline li {
    width: 20%;
  }
}
.timeline li:last-child .count:after {
  display: none;
}
.timeline li:first-child .count:before {
  display: none;
}
.timeline strong {
  display: block;
  color: #D1D5CE;
  font-size: 12px;
  line-height: 16px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
@media (min-width: 576px) {
  .timeline strong {
    font-size: 18px;
    line-height: 21px;
  }
}
.timeline .count {
  margin-bottom: 40px;
  border-radius: 100%;
  width: 51px;
  height: 51px;
  background: #fff;
  border: 1px solid #D1D5CE;
  text-align: center;
  font-size: 18px;
  line-height: 51px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: rgba(167, 147, 106, 0.987);
  display: inline-block;
  position: relative;
}
@media (min-width: 576px) {
  .timeline .count {
    margin-bottom: 80px;
  }
}
.timeline .count:after {
  content: "";
  background: #D1D5CE;
  width: 9999px;
  height: 2px;
  position: absolute;
  top: 25px;
  right: -9999px;
  bottom: auto;
  left: auto;
}
.timeline .count:before {
  content: "";
  background: #D1D5CE;
  width: 9999px;
  height: 2px;
  position: absolute;
  top: 25px;
  right: auto;
  bottom: auto;
  left: -9999px;
}

.features-area {
  overflow: hidden;
  padding-top: 40px;
  padding-bottom: 40px;
}
@media (min-width: 768px) {
  .features-area {
    padding-top: 74px;
    padding-bottom: 64px;
  }
}
.features-area [class^=col-] {
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .features-area [class^=col-] {
    margin-bottom: 0;
  }
}

.box {
  border-radius: 4px;
  padding: 20px 50px;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #D1D5CE;
  text-align: center;
  background: #183B59;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
}
.box.platform {
  background: #397087;
  color: #fff;
}
.box.platform p {
  color: #fff;
}
.box.prices {
  background: #edeeeb;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.1);
  color: #397087;
}
.box.prices p {
  color: #397087;
}
.box p {
  font-size: 1.5rem;
  font-weight: 400;
  color: #D1D5CE;
}

.booking-banner {
  overflow: hidden;
  background: #A8946B;
  padding-top: 80px;
  padding-bottom: 45px;
  text-align: center;
}
.booking-banner h1 {
  color: #fff;
}
.booking-banner .head {
  margin-bottom: 50px;
}

.competitiveness-holder {
  padding-top: 40px;
  padding-bottom: 10px;
  color: #2B2B2B;
}
@media (min-width: 768px) {
  .competitiveness-holder {
    padding-top: 109px;
    padding-bottom: 40px;
  }
}
@media (min-width: 992px) {
  .competitiveness-holder {
    padding-top: 109px;
    padding-bottom: 86px;
  }
}
.competitiveness-holder .clients {
  margin-left: auto;
}
.competitiveness-holder [class^=col-] {
  margin-bottom: 20px;
}
@media (min-width: 992px) {
  .competitiveness-holder [class^=col-] {
    margin-bottom: 0;
  }
}

.offer-holder {
  background: rgba(209, 213, 206, 0.4);
  padding-top: 53px;
  position: relative;
  margin-bottom: 40px;
  padding-bottom: 40px;
}
@media (min-width: 768px) {
  .offer-holder {
    margin-bottom: 120px;
  }
}
@media (min-width: 992px) {
  .offer-holder {
    margin-bottom: 196px;
    padding-bottom: 0;
  }
}
.offer-holder:before {
  content: "";
  width: 79px;
  height: 193px;
  position: absolute;
  top: auto;
  right: 0;
  bottom: -100px;
  left: auto;
  background-image: url(../images/shape-3.svg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  display: none;
}
@media (min-width: 768px) {
  .offer-holder:before {
    display: block;
  }
}
@media (min-width: 992px) {
  .offer-holder:before {
    width: 139px;
    height: 340px;
    position: absolute;
    top: auto;
    right: 0;
    bottom: -174px;
    left: auto;
  }
}
.offer-holder.booking {
  padding-top: 40px;
  padding-bottom: 40px;
  margin-bottom: 56px;
}
@media (min-width: 768px) {
  .offer-holder.booking {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .offer-holder.booking {
    padding-top: 129px;
    padding-bottom: 71px;
  }
}
.offer-holder.booking:before {
  display: none;
}
.offer-holder .logo {
  max-width: 282px;
  margin: 30px auto 20px;
  position: relative;
  top: 20px;
}
@media (min-width: 768px) {
  .offer-holder .logo {
    margin: 70px auto 0;
  }
}
.offer-holder .logo img {
  width: 100%;
}
.offer-holder .signup-holder {
  margin: 0 auto 60px;
}
@media (min-width: 992px) {
  .offer-holder .signup-holder {
    margin: 0 0 -112px;
  }
}

.custom-list li {
  display: block;
}

.signup-holder {
  border-radius: 3px;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.3);
  border: 2px solid #2B2B2B;
  padding: 26px 20px;
  max-width: 551px;
}
@media (min-width: 768px) {
  .signup-holder {
    padding: 46px 53px;
  }
}
.signup-holder.booking {
  border: none;
  padding: 0;
  box-shadow: none;
  margin-bottom: 40px;
  max-width: 500px;
}
@media (min-width: 992px) {
  .signup-holder.booking {
    margin-bottom: 0;
  }
}
.signup-holder.booking .btn-holder {
  margin-left: 0;
}
.signup-holder.booking h1 {
  margin-bottom: 40px;
}

.list {
  font-size: 1.125rem;
}
.list li {
  position: relative;
  padding-left: 46px;
  margin-bottom: 15px;
}
@media (min-width: 768px) {
  .list li {
    padding-left: 69px;
  }
}
.list li:after {
  content: "";
  background-image: url(../images/ico-check.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 30px;
  height: 28px;
  position: absolute;
  top: 8px;
  right: auto;
  bottom: auto;
  left: 0;
}
@media (min-width: 768px) {
  .list li:after {
    width: 40px;
    height: 37px;
    position: absolute;
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
  }
}

.custom-list {
  font-size: 1.125rem;
}
.custom-list .c-count {
  width: 38px;
  height: 38px;
  border-radius: 100%;
  background: #397087;
  font-size: 18px;
  line-height: 38px;
  color: #fff;
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 0;
  text-align: center;
}
.custom-list svg {
  fill: #fff;
}
.custom-list li {
  padding-left: 50px;
  position: relative;
  margin-bottom: 15px;
  color: #2B2B2B;
  font-weight: 400;
}
@media (min-width: 768px) {
  .custom-list li {
    padding-left: 69px;
    margin-bottom: 30px;
  }
}

.details-holder {
  overflow: hidden;
}
.details-holder.process {
  padding-top: 40px;
  padding-bottom: 20px;
}
@media (min-width: 768px) {
  .details-holder.process {
    padding-top: 72px;
    padding-bottom: 20px;
  }
}
.details-holder .text {
  max-width: 498px;
}
.details-holder .text.center {
  margin: 0 auto;
}
.details-holder h1 {
  margin-bottom: 20px;
}
@media (min-width: 768px) {
  .details-holder h1 {
    margin-bottom: 28px;
  }
}
.details-holder .text-block {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .details-holder .text-block {
    margin-bottom: 60px;
  }
}
.details-holder [class^=col-] {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .details-holder [class^=col-] {
    margin-bottom: 101px;
  }
}

.cards-holder {
  overflow: hidden;
}

.steps-holder {
  overflow: hidden;
}
.steps-holder .row {
  margin: 0 -13px;
}
.steps-holder [class^=col-] {
  padding-left: 7px;
  padding-right: 7px;
  margin-bottom: 20px;
  overflow: hidden;
}
@media (min-width: 1240px) {
  .steps-holder [class^=col-]:first-child .badge:after {
    display: none;
  }
}
@media (min-width: 1240px) {
  .steps-holder [class^=col-]:last-child .badge:before {
    display: none;
  }
}
.steps-holder .head {
  margin-bottom: 40px;
}
.steps-holder .card {
  background: #edeeeb;
  padding: 22px 36px;
  letter-spacing: 0.06em;
  font-size: 1.125rem;
  border: none;
  height: 100%;
}
.steps-holder .card.freight .badge {
  background: #183B59;
}
.steps-holder .card.carrier .badge {
  background: #D1D5CE;
  color: #183B59;
}
.steps-holder .card.manage .badge {
  background: #397087;
}
.steps-holder .card h3 {
  margin-bottom: 25px;
}
.steps-holder .card p {
  font-size: 1.125rem;
}

.badge {
  width: 200px;
  height: 200px;
  border-radius: 100%;
  background: rgba(167, 147, 106, 0.987);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  text-align: center;
  font-size: 1rem;
  line-height: 1.3;
  color: #fff;
  margin-bottom: 47px;
  position: relative;
  z-index: 2;
  word-break: break-all;
  white-space: normal;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  .badge {
    width: 200px;
    height: 200px;
    font-size: 1.25rem;
  }
}
@media (min-width: 1400px) {
  .badge {
    width: 264px;
    height: 264px;
    font-size: 1.5rem;
  }
}
.badge.simple:after, .badge.simple:before {
  display: none;
}
.badge:after {
  content: "";
  position: absolute;
  top: 90px;
  right: auto;
  bottom: auto;
  left: -9999px;
  width: 9999px;
  height: 20px;
  z-index: 1;
  background: #397087;
}
@media (min-width: 992px) {
  .badge:after {
    position: absolute;
    top: 90px;
    right: auto;
    bottom: auto;
    left: -9999px;
  }
}
@media (min-width: 1400px) {
  .badge:after {
    position: absolute;
    top: 125px;
    right: auto;
    bottom: auto;
    left: -9999px;
  }
}
.badge:before {
  content: "";
  position: absolute;
  top: 90px;
  right: -9999px;
  bottom: auto;
  left: auto;
  width: 9999px;
  height: 20px;
  z-index: 1;
  background: #397087;
}
@media (min-width: 992px) {
  .badge:before {
    position: absolute;
    top: 90px;
    right: -9999px;
    bottom: auto;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .badge:before {
    position: absolute;
    top: 125px;
    right: -9999px;
    bottom: auto;
    left: auto;
  }
}

.banner-inner {
  overflow: hidden;
  background: #A8946B;
  padding-top: 40px;
  padding-bottom: 40px;
}
@media (min-width: 768px) {
  .banner-inner {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media (min-width: 1240px) {
  .banner-inner {
    padding-top: 193px;
    padding-bottom: 90px;
  }
}
.banner-inner .head {
  max-width: 713px;
  margin-bottom: 98px;
}
.banner-inner .head h1 {
  color: #fff;
}

.other-services {
  background: #183B59;
  text-align: center;
  font-weight: 700;
  font-size: 1.025rem;
  line-height: normal;
  color: #fff;
  padding: 16px 10px;
  margin-bottom: 31px;
  border-radius: 4px;
}
@media (min-width: 576px) {
  .other-services {
    font-size: 1.5rem;
  }
}
@media (min-width: 768px) {
  .other-services {
    font-size: 1.875rem;
  }
}

.triangle-up {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 170px solid transparent;
  border-right: 170px solid transparent;
  border-top: 140px solid rgba(209, 213, 206, 0.4);
}
@media (min-width: 768px) {
  .triangle-up {
    border-left: 348px solid transparent;
    border-right: 348px solid transparent;
    border-top: 165px solid rgba(209, 213, 206, 0.4);
  }
}

.services-holder .card {
  height: 100%;
}
.services-holder [class^=col-] {
  margin-bottom: 15px;
}
@media (min-width: 992px) {
  .services-holder [class^=col-] {
    margin-bottom: 0;
  }
}

.logo-holder {
  margin: 20px auto 31px;
  max-width: 700px;
  text-align: center;
  position: relative;
}
.logo-holder .logo {
  width: 190px;
  margin: 0 auto;
  position: absolute;
  top: 50%;
  right: auto;
  bottom: auto;
  left: 50%;
  transform: translate(-50%, -140%);
}
@media (min-width: 768px) {
  .logo-holder .logo {
    width: 241px;
    transform: translate(-50%, -90%);
  }
}
.logo-holder .logo img {
  width: 100%;
}

.contact-btn-holder {
  max-width: 600px;
  margin: 0 auto 67px;
}

.faq-banner {
  background: #183B59;
  height: 400px;
  margin-bottom: 87px;
  position: relative;
}
@media (min-width: 768px) {
  .faq-banner {
    height: 500px;
  }
}
@media (min-width: 992px) {
  .faq-banner {
    height: 799px;
  }
}
.faq-banner .container {
  height: 100%;
  display: flex;
  align-items: center;
}
.faq-banner:before {
  content: "";
  width: 293px;
  height: 404px;
  position: absolute;
  top: 50%;
  right: 0;
  bottom: 13px;
  left: auto;
  transform: translateY(-50%);
  background-image: url(../images/shape-5.svg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  display: none;
}
@media (min-width: 992px) {
  .faq-banner:before {
    display: block;
  }
}
.faq-banner .text {
  margin: 0 auto;
  max-width: 500px;
  text-align: center;
}
.faq-banner .text p {
  color: #D1D5CE;
}
.faq-banner h1 {
  color: #fff;
}

.card {
  border: none;
  border-radius: 0;
  background: rgba(209, 213, 206, 0.4);
  border-radius: 4px;
  letter-spacing: 0.01em;
}
.card .card-body {
  padding: 30px;
}
@media (min-width: 768px) {
  .card .card-body {
    padding: 60px;
  }
}
@media (min-width: 992px) {
  .card .card-body {
    padding: 100px 80px;
  }
}
.card h1 {
  margin-bottom: 40px;
}
.card h3 {
  margin-bottom: 40px;
  color: #A8946B;
}

.bg-white {
  background: #fff;
}

.faq-holder {
  background: #EDEEEB;
  margin-bottom: 60px;
}
@media (min-width: 768px) {
  .faq-holder {
    margin-bottom: 100px;
  }
}
.faq-holder .other-services {
  top: -35px;
  position: relative;
}
.faq-holder p {
  color: #2B2B2B;
}
.faq-holder .block {
  margin-bottom: 30px;
}
@media (min-width: 768px) {
  .faq-holder .block {
    margin-bottom: 80px;
  }
}
.faq-holder .block-holder {
  padding: 20px 0 30px;
  max-width: 1150px;
  margin: 0 auto;
}
@media (min-width: 768px) {
  .faq-holder .block-holder {
    padding: 30px 0 50px;
  }
}

.info-tag {
  display: inline-flex;
  align-items: center;
  font-size: 1.125rem;
  line-height: normal;
  color: #A8946B;
  letter-spacing: 0.06em;
}
.info-tag img {
  margin-right: 29px;
}

.info-badges {
  text-align: center;
}
.info-badges .badge {
  background: #183B59;
  white-space: normal;
  font-size: 24px;
  line-height: 40px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.clients-holder {
  overflow: hidden;
  padding-top: 40px;
}
@media (min-width: 768px) {
  .clients-holder {
    padding-top: 78px;
  }
}
.clients-holder .head {
  margin-bottom: 30px;
}

#footer {
  overflow: hidden;
  background: #183B59;
  padding-top: 71px;
  padding-bottom: 30px;
  color: #fff;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
#footer .logo {
  width: 153px;
}
#footer .logo img {
  width: 100%;
}
#footer a {
  color: #fff;
}
#footer a:hover {
  color: #A8946B;
}

address {
  font-style: normal;
  line-height: 2.6;
  margin-bottom: 30px;
}
address span {
  display: block;
}

.copyright {
  display: block;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  color: #fff;
  font-family: "Nunito", "Montserrat", sans-serif;
  line-height: 1.2;
  padding: 41px 0 0;
}

.f-nav {
  overflow: hidden;
  margin-bottom: 30px;
}
.f-nav ul li {
  display: block;
  margin-bottom: 15px;
}
.f-nav ul li a {
  color: #fff;
}

.landingBanner {
  overflow: hidden;
  position: relative;
  height: auto;
  padding: 30px;
  text-align: center;
  background: #183B59;
  min-height: 400px;
}

@media (min-width: 768px) {
  .landingBanner {
    text-align: center;
  }
}
@media (min-width: 992px) {
  .landingBanner {
    height: 55vh;
    padding: 15px;
  }
}
@media (min-width: 1200px) {
  .landingBanner {
    height: 60vh; 
  }
}

@media (min-width: 1700px) {
  .landingBanner {
    height: calc(50vh - (107.141px / 2));
  }
}

.landingBanner:after {
  content: "";
  width: 119px;
  height: 161px;
  position: absolute;
  top: 50%;
  bottom: 33px;
  left: -50px;
  transform: translateY(-50%);
  background-image: url(../images/shape-6.svg);
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}
@media (max-width: 991.98px) {
  .landingBanner:after {
    margin-left: -140px;
  }
}
@media (max-width: 575.98px) {
  .landingBanner:after {
    margin-left: -80px;
  }
}
@media (min-width: 576px) {
  .landingBanner:after {
    width: 210px;
    height: 287px;
  }
}
@media (min-width: 992px) {
  .landingBanner:after {
    width: 220px;
    height: 304px;
  }
}
@media (min-width: 1400px) {
  .landingBanner:after {
    width: 370px;
    height: 370px;
    margin-left: -20px;
  }
}

@media (max-width: 991.98px) {
  .landingBanner:before {
    display: none;
  }
}
@media (min-width: 576px) {
  .landingBanner:before {
    width: 85px;
    height: 227px;
  }
}
@media (min-width: 1240px) {
  .landingBanner:before {
    width: 139px;
    height: 370px;
  }
}
.landingBanner .container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bannerText {
  width: 100%; 
  display: flex; 
  justify-content: center;
}

.landingBanner .text-holder {
  margin-bottom: 30px;
  padding: 0 10px;
  color: #fff;
}
@media (min-width: 768px) {
  .landingBanner .text-holder {
    margin-bottom: 30px;
    padding: 0;

  }
}
@media (max-width: 767.98px) {
  .landingBanner .cta-holder {
    text-align: center;
  }
}

@media (min-width: 992px) {
  .landingBanner .text-holder {
    margin-bottom: 30px;
    max-width:70%;
    padding: 0;
  }
}

@media (min-width: 1400px) {
  .landingBanner .text-holder {
    margin-bottom: 30px;
    max-width:80%;
    padding: 0;
  }
}

@media (min-width: 1820px) {
  .landingBanner .text-holder {
    margin-bottom: 30px;
    max-width:100%;
    padding: 0;

  }
}

.landingBanner .text {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 40px;
  color:#FFF;
  letter-spacing: 0.06em;
}


.landingBanner .subText {
  font-family: 'Montserrat';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 40px;
text-align: center;
letter-spacing: 0.06em;
color: #D1D5CE;
}

.videoBanner {
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  min-height: 500px;
}

@media (min-width: 992px) {
  .videoBanner {
    height: 55vh;
    min-height: 500px;
  }
}

@media (min-width: 1200px) {
  .videoBanner {
    height: 60vh;
    min-height: 500px;
  }
}

@media (min-width: 1700px) {
  .videoBanner {
    height: calc(50vh - (107.141px / 2));
    padding: 20px;
    min-height: 500px;
  }
}


.video-wrapper {
  position: relative;
  padding-bottom: 56.25%; 

}

.video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.videoList-wrapper {
  height: auto; 
  display: flex; 
  justify-content: space-between;
}


.videoList-container {

  margin-left: 20px; /* adjust as needed */
  display: grid; 
  height: 100%;

}


@media only screen and (max-width: 992px) {
  .video-container {
    flex-direction: column;
    align-items: center;
    height: auto;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  
  .videoList-container {
    margin: 20px 0;
    height: 100%;
  }
}

@media only screen and (max-width: 992px) {
  .videoList-container {
    width: 100%;
    margin-top: 50px;
  }
}

@media (min-width: 992px) {
  .videoListItem {
    font-size: 0.7em;
  }
}

@media (min-width: 1240px) {
  .videoListItem {
    font-size: 1.125rem;
  }
}

@media (min-width: 992px) {
  .video-cta {
    font-size: 1em;
    margin-left: 60px;
    margin-bottom: 10px;
  }
}

@media (min-width: 1240px) {
  .video-cta {
    margin-left: 0px;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 992px) {
  .videoList-btn-row {
    margin-left: 0;
  }
}

@media only screen and (max-width: 992px) {
  .videoList-btn {
    display: flex; 
    align-items: center;
    justify-content: center;
    margin-top: 50px;
  }
}

@media only screen and (min-width: 992px) {
  .videoList-btn {
    display: flex; 
    align-items: end;
  }
}

@media only screen and (max-width: 992px) {
  .video-cta-col {
    display: flex; 
    align-items: center;
    justify-content: center;
  }
}

@media only screen and (min-width: 992px) {
  .video-cta-col {
    display: flex; 
    align-items: end;
  }
}
.reference-banner {
  display: flex;
  align-items: center;
  overflow: hidden;
  background: #183B59;
  padding-top: 80px;
  padding-bottom: 45px;
  text-align: center;
}
.reference-banner h1 {
  color: #fff;
}
.reference-banner .head {
  margin-bottom: 50px;
}

.reference-banner img {
  display: block;
  margin: 0 10px;
}

@media only screen and (max-width: 992px) {
  .text-center-sm {
    text-align: center;
    margin-bottom: 10px;
  }
}

@media only screen and (min-width: 992px) {
  .margin-left-md {
    margin-left: 40px;
  }
}

.stars-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.stars-wrapper img {
  margin: 0 10px;
}

.callToAction {
  height: 150px; 
  display: flex;
  justify-content: center;
  align-items: center; 
}

.cta-btn {
  display: inline-block; /* or inline-flex */
  text-align: center;
  height: auto; 
}

.logoBanner {
  display: flex;
  justify-content: center;
  align-items: center; 
}

.logoHolder {
  display: flex;
  justify-content: center;
  align-items: center; 
  margin-top: 100px;
}

.logoHolder img {
  width: 400px;
  margin-bottom: 30px;
}

.logoContainer {
  height: 400px;
}



@media only screen and (max-width: 992px) {
  .details-order-1 {
    order: 1;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .details-order-2 {
    order: 2;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .details-order-3 {
    order: 3;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .details-order-4 {
    order: 4;
    display: flex;
    justify-content: center;
    text-align: center;
  }
}

@media only screen and (min-width: 992px) {
  .details-order-1 {
    order: 2;
  }
  .details-order-2 {
    order: 1;
  }
  .details-order-3 {
    order: 3;
  }
  .details-order-4 {
    order: 4;
  }
}
